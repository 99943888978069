import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createDeviceDetector } from "next-vue-device-detector";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

export const device = createDeviceDetector();

createApp(App).use(router).use(device).mount('#app')
